#social-bar {
    margin: 30px 0;
    /* margin-bottom: 150px; */
    text-align: center;
}

#social-bar img {
    margin: 0 9px;
    width: 8%;
    /* opacity: 0.7; */
}

