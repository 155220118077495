#page {
    width: 100vw;
    height: 100vh;
}

#page #box {
    width: 70vw;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    text-align: center;
}

#page #box img { width: 40vw; }

#page #box h1 { font-size: 2em; }