@import '_include-media.scss';

//--- MEDIA QUERIES -------------------------------

// If you want to define custom breakpoints
// $breakpoints: (small: 320px, medium: 768px, large: 1024px);

// Test media query
// @include media(">phone") {
//     body {
//         margin: 100px;
//     }
// }

//--- COLORS ---------------------------------------

$primary: #3C4858;
$secondary: #D8EACC;
// $primary: #E46C1C;
// $secondary: #ED8C4C;

.primary-color { color: $primary !important; }
.secondary-color { color: $secondary !important; }

//--- FONT ----------------------------------------

@mixin open-sans-font {
    font-family: 'Open Sans', sans-serif !important;
}

.center-text { text-align: center !important; }

//--- SHARED --------------------------------------

body { 
    margin: 0;
    @include open-sans-font;
}

div#root {
    overflow-x: hidden;
    overscroll-behavior: none;
}
.newsletter {
    h3 { 
        font-weight: 400; 
        color: $primary;
    }
    justify-content: center;
    align-items: center;
    .button-down {
        background-color: #D8EACC;
        min-width: 116px;
        min-height: 40px;

        // background: linear-gradient(180deg, rgba(239, 180, 141, 0.8) 10.94%, rgba(249, 98, 0, 0.8) 75%), #F4A26D;
        // background: linear-gradient(180deg, rgba(210, 222, 238, 0.8) 10.94%, rgba(60, 72, 88, 0.8) 95.83%), #3C4858;
        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 34px;
        border: 0;

        color: #3c4858;
        font-size: 1em;
        padding: 15px;
        font-family: Arial

    }
}
.paddingIframe {
    overflow-y: hidden;
}

.paddingIframe {
    iframe{
        margin-top:-52px;
    }
}


.rounded-page {
    padding-top: 30px;
    background-color: #fff;
    box-shadow: 0px -24px 47px rgba(0, 0, 0, 0.09);
    border-radius: 45px 45px 0 0;

    .title-page { margin-top: 0; }
}

button.custom-button {
    min-width: 116px;
    min-height: 40px;
    
    // background: linear-gradient(180deg, rgba(239, 180, 141, 0.8) 10.94%, rgba(249, 98, 0, 0.8) 75%), #F4A26D;
    // background: linear-gradient(180deg, rgba(210, 222, 238, 0.8) 10.94%, rgba(60, 72, 88, 0.8) 95.83%), #3C4858;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: #3C4858;
    border-radius: 34px;
    border: 0;
    
    color: #fff;
    font-size: 1em;
    padding: 15px;
}

button.custom-button:disabled { 
    
    background: linear-gradient(180deg, rgba(208,208,208,1) 11%, rgba(148,148,148,1) 75%, rgba(148,148,148,1) 100%);
}

#link-tracciabilita {
    margin: 10px 0;
    display: block;
}

@mixin back-gradient {
    background: linear-gradient(0deg, #D8EACC, #D8EACC);
}

//--- CUSTOMIZE MATERIAL UI ---------------------------------------

.MuiContainer-root { padding: 0 32px !important; }

.MuiSnackbar-anchorOriginBottomCenter { bottom: 0 !important; }
.MuiSnackbar-root {
    left: 0 !important;
    right: 0 !important;
    .MuiSnackbarContent-root {
        color: #000 !important;
        background-color: #fff !important;
        border-radius: 34px 34px 0 0 !important;
        .MuiSnackbarContent-action { 
            margin-right: 0 !important;
            padding: 0 10px !important;
            a { color: $primary; }
        }
    }
}

//--- PAGES ---------------------------------------

@import "_product-page.scss";
@import "_company-page.scss";
@import "_partners-page.scss";
@import "_traceability-page.scss";
