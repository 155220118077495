#rotation-error {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: #fff;
}

#rotation-error #box {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#rotation-error #box svg { 
    font-size: 4em; 
    margin-bottom: 10px;
}