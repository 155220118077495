/*-- Sizes variant -----------*/
.small {
  width: 2vh;
  height: 2vh;
}

.medium {
  width: 4vh;
  height: 4vh;
}

.large {
  width: 20vh;
  height: 20vh;
}

/*-- Colors variant -----------*/
.dark { color: #000; }
.light { color: #fff; }

/*-- Logo Loade ---------------*/
#logo-loader-container {
  width:100vw;
  height: 100vh;

  position: fixed;
  z-index: 1000;

  background-color: #fff;
}

#logo-loader-container #logo-loader-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#logo-loader-container #logo-loader-content img {
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);
  animation: splash-logo 1.5s ease-out infinite;
}

#logo-loader-container #logo-loader-content h3 {
  margin: 5px 0;
  color: #3c4858;
  font-weight: 400;
}

@keyframes splash-logo {
  25% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
  45% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(-15deg);
    transform-origin: 50% 80%;
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
}
