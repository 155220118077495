#custom-modal .MuiDialog-scrollPaper { align-items: normal; }
#custom-modal .MuiFab-primary { background-color: rgba(60, 72, 88, 0.9); }

#custom-modal .close-button {
    width: 15vw;
    height: 15vw;

    position: fixed;
    left: 50%;
    bottom: 7.5vw;
    transform: translateX(-50%);
    z-index: 100;
}
