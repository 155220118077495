#product-page {
    margin-bottom: -80px;
    
    h1, h2, h3, h4 { color: $primary; }

    section#header {
        @include back-gradient;
        
        padding-bottom: 100px;
        overflow: auto;

        text-align: center;
        // color: #fff;

        #logo {
            $size: 33vw;

            height: $size;
            position: relative;

            padding: 0;
            // padding-top: 40px;
            margin-top: 40px;

            img { 
                width: $size - 3vw;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                z-index: 1;
            }
            
            span.circle {
                width: $size;
                height: $size;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                background-color: #fff;
                border-radius: 100%;
            }
        }

        h1#product-title {
            font-size: 1.5rem;
            color: $primary;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        p#description { 
            text-align: left; 
            font-size: 0.8em;
        }

        #product-image {
            $size: 70vw;

            position: relative;
            height: $size;

            margin: 40px;
    
            img { 
                height: 100%; 
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
    
            span#circle {
                height: $size - 5vw;
                width: $size - 5vw; 

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                z-index: 0;

                background: #fff;
                opacity: 0.83;
                box-shadow: 0px 11px 7px rgba(0, 0, 0, 0.11), inset 18px 18px 30px #D1D9E6, inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
            }
        }

    }

    section.rounded-page {
        position: relative;
        top: -80px;

        article#ingredienti,
        article#aroma,
        article#esperienza {
            .content {
                position: relative;
                z-index: 1;

                background: $secondary;
                backdrop-filter: blur(14px);
                border-radius: 24px;

                padding: 15px;

                p { 
                    font-size: 0.9em; 
                    margin: 0;
                }
            }
        }

        article#ingredienti {
            position: relative;
            .image {
                height: 40vw;
                img { 
                    width: 100%; 
                    position: absolute;
                }
            }
        }

        article#aroma {
            margin-top: 50px;
            position: relative;
            img {
                width: 100%;

                position: absolute;
                right: 0;
                top: 20%;
                // transform: translateY(-50%);

                z-index: 0;
            }
        }

        article#esperienza {
            margin-top: 150px;
            position: relative;
            h3 { text-align: right; }
            img {
                width: 50%;

                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                z-index: 0;
            }
        }

        article#valori-nutrizionali {
            margin-top: 50px;
            color: $primary;
            p { 
                text-align: center; 
                margin: 30px 0;
            }
            table, tr, td { border: 1px solid $primary; }
            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                tr td { padding: 3px; }
            }
        }

        article#choco-fair {
            margin-top: 100px;
            text-align: center;

            img { width: 90%; }

            #content {
                background-color: $secondary;
                border-radius: 24px;
                padding-top: 20px;
                padding-bottom: 30px;

                text-align: left;

                img { width: 100%; }

                p { font-size: 0.9em; }
            }
        }

    }

}