#certification-page {
    margin-bottom: -80px;
    color: #000;

    h1 { 
        color: $primary; 
        font-size: 1.8em;
    }

    section#header {
        @include back-gradient;
        padding: 100px 0;

        #logo-circle { 
            width: 100%;
            position: relative;
            top: -40px;
            
            #circle {
                width: 60vw;
                height: 60vw;
                
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                
                background: #fff;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.11), inset 0px 10px 63px rgba(0, 0, 0, 0.21), inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
                
                img { 
                    width: 40vw; 
                    
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }   

    section.rounded-page {
        position: relative;
        top: -80px;
        
        .content { font-size: 0.9em; }

        section#partners {
            margin-top: 50px;
            text-align: center;

            article.card {
                padding: 40px 0;
            
                img { width: 100%; }
                
                .link {
                    margin: 20px 0;
                    a {
                        color: $primary;
                        font-size: 1.2em;
                        font-weight: 800;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}