#traceability-page {
    margin-bottom: -80px;

    h1 { color: $primary; }

    section#header {
        @include back-gradient;
        padding: 100px 0;

        #logo-circle { 
            width: 100%;
            position: relative;
            top: -40px;

            #circle {
                $size: 60vw;
                width: $size;
                height: $size;
                
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                
                background: #fff;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.11), inset 0px 10px 63px rgba(0, 0, 0, 0.21), inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
                
                img { 
                    width: 60vw; 
                    
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

    }

    section.rounded-page {
        position: relative;
        top: -80px;

        section#first-page {
            img {
                width: 100%;
                height: auto;
                margin: 10px 0;
                // box-shadow: 6px 6px 13px -3px rgba(0,0,0,0.63);
            }
            #callout {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }

        section#cards {
            //! DEBUG MODE
            $debug: false;

            >article {
                position: relative;
                z-index: 1;
            }
    
            article {
                $title-padding: 10px;
                $sizes: (30vh, 10vh, calc(25vh - #{$title-padding} * 2), 35vh);

                background-color: #fff;
    
                section.image { 
                    @if $debug { border:1px solid blue; }
    
                    height: nth($sizes, 1);
                    background-repeat: no-repeat;
                    background-position: top center;
                    background-size: cover;
                }
    
                section.title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
    
                section.title {
                    @if $debug { border:1px solid violet; }
    
                    height: nth($sizes, 2);
                    padding: $title-padding 0;
    
                    h1 { 
                        text-align: center;
                        @include open-sans-font;
                        font-weight: 800;
                        font-size: 1.3em;
                        margin: 0;
                        padding: 0;
                    }
                }
    
                section.description {
                    @if $debug { border:1px solid green; }
    
                    height: nth($sizes, 3);
                    font-size: .9em;
                    line-height: 22px;
                    color: #000;
    
                    em { 
                        color: $primary; 
                        font-style: normal;
                    }
    
                    a { color: $primary; }
                }

                .centerContent {
                   
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
    
                section.button {
                    @if $debug {border:1px solid red; }
                    
                    padding-top: 15px;

                    height: nth($sizes, 2);
                    
                    .button-down {
                        background-color: #D8EACC;
                        min-width: 116px;
                        min-height: 40px;
    
                        // background: linear-gradient(180deg, rgba(239, 180, 141, 0.8) 10.94%, rgba(249, 98, 0, 0.8) 75%), #F4A26D;
                        // background: linear-gradient(180deg, rgba(210, 222, 238, 0.8) 10.94%, rgba(60, 72, 88, 0.8) 95.83%), #3C4858;
                        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                        border-radius: 34px;
                        border: 0;
    
                        color: #3c4858;
                        font-size: 1em;
                        padding: 15px;
                        font-family: Arial

                    }
    
                    .logo-circle {
                        // height: 25vh;
                        // position: relative;
                        width:100%;
    
                        .circle {
                            position: absolute;
                            // top: 50%;
                            // left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20vh;
                            height: 20vh;
                            border-radius: 100%;
                            // background-color: #F4A26D;
                            // background: linear-gradient(180deg, #EFB48D 0%, #F96200 80.21%);
                            background-color: $secondary;
                            // box-shadow: inset -7px -10px 17px #E8A375, inset 9px 21px 29px -19px #FFFFFF;
                        }
                        /* added after comment on height fixed and position*/
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // img {
                        //     height: 100%;
                        //     width: auto;
                        //     position: absolute;
                        //     top: 50%;
                        //     left: 50%;
                        //     transform: translate(-50%, -50%);
                        // }
                        .inner-image {
                            height: 15vh;
                            width: 30%;
                            margin-top:-40px;
                            margin-bottom:20px;
                            
                            // transform: translate(-50%, -50%);
                        }
                        .inner-image-last-card {
                            height: auto;
                            width: 30%;
                            margin-top:-40px;
                            margin-bottom:20px;
                            
                            // transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    
        section#blockchain {
            margin: 20px 0;
    
            p { 
                font-size: 0.7em;
                span { color: $primary; }
            }
        
            img { width: 100%; }
    
            .verifica {
                text-align: center;
                margin-top: 20px;
            }
        }
    
        section#last-page {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 100px;
    
            img { width: 100%; }
            h3 { 
                font-weight: 400; 
                color: $primary;
            }
            ul {
                list-style: none;
                padding: 15px 0;
                margin-top: 30px;
                // background: linear-gradient(180deg, #F4A26D 15.1%, #E46C1C 77.08%);
                background-color: $secondary;
                border-radius: 34px;
                // color: #fff;
                color: $primary;
    
                li {
                    margin: 40px 0;
                    img { width: 25vw; }
                    div {
                        margin-top: 20px;
                        font-weight: 800;
                    }
                }

                li:nth-child(2) img { width: 21vw; }
            }
    
            p { 
                font-weight: 800;
                margin: 5px;
                margin-top: 50px;
            }
            a { color: $primary; }
        }
    }

}

.blockchain-modal {
    section#header {
        @include back-gradient;
        text-align: center;
        h1 { 
            color: $primary;
            padding-top: 50px;
        }
        img {
            margin: 20px 0;
            margin-bottom: 100px;
            width: 80%;
        }
    }
    section.rounded-page {
        position: relative;
        top: -80px;
        margin-bottom: -80px;

        p#main-content { 
            font-size: 0.9em; 
            
            em {
                color: $primary;
                font-style: normal;
            }
        }
        h1 { 
            padding-top: 30px; 
            color: $primary;
            text-align: center;
        }

        #detail {
            margin-top: 30px;
            div:nth-child(odd) { text-align: center; }
            div:last-child { color: #23B6BF; }
            div:nth-child(4n - 2) { 
                font-weight: 800; 
                color: $primary;
            }
            .MuiTypography-body1 { font-family: inherit; }
        }
        #download {
            margin-top: 40px;
            div {
                text-align: center;
                img { width: 15vw; }
            }
        }
    }
}

.more-text-modal {
    section#header {
        .image {
            position: relative;
            height: 40vh;
            background-repeat: no-repeat;
            background-position: center center;
        }
        padding-bottom: 20px;
    }

    section#body {
        h1 {
            font-size: 1.7em;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 5px;
            color: $primary;
        }

        em {
            color: $primary;
            font-style: normal;
        }
        #callout {
            padding-top: 20px;
        }

    }
}
.tabella-tracciabilita {
    margin-top: 50px;
    color: $primary;
    p { 
        text-align: center; 
        margin: 30px 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 2px solid #000;
        tr {
          td { padding: 5px; }
          td:first-child {
            width: 70%;
            border-right: 1px dotted #000;
          }
        }
        /* Titolo casella */
        tr:nth-child(2n + 1) td { font-size: 0.8em; }
        tr:nth-child(4n) { border-bottom: 2px solid #000; }
        tr:nth-child(4n + 2) {
          border-bottom: 1px dotted #000;
          td { padding: 8px 5px; }
        }
      }
}

.table5 {
    margin-top: 50px;
    color: $primary;
    p { 
        text-align: center; 
        margin: 30px 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 2px solid #000000de;
        tr {
          td { padding: 5px; }
          td:first-child {
            width: 50%;
            border-right: 1px dotted #000000de;
          }
        }
        /* Titolo casella */
        tr:nth-child(2n + 1) td { font-size: 0.8em; }
        tr:nth-child(3n) { border-bottom: 2px solid #000000de; }
        tr:nth-child(4n + 2) {
          border-bottom: 1px dotted #000000de;
          td { padding: 8px 5px; }
        }
      }
}

.alertDetail {
    p { 
        @include open-sans-font;
    }
    span {
        @include open-sans-font;
    }
    h2 { 
        text-align: center;
        @include open-sans-font;
        font-weight: 700;
        font-size: 1em;
        margin: 0;
        padding: 0;
    }
}
.tabsStep1 {
    margin-top: 50px;
    color: $primary;
    p { 
        text-align: center; 
        margin: 30px 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 2px solid #000000de;
        tr {
          td { padding: 5px; }
          td:first-child {
            width: 70%;
            border-right: 1px dotted #000000de;
          }
        }
        /* Titolo casella */
        tr:nth-child(2n + 1) td { font-size: 0.8em; }
        tr:nth-child(4n) { border-bottom: 1px dotted #000000de; }
        tr:nth-child(4n + 2) {
          border-bottom: 2px solid #000000de;
          td { padding: 8px 5px; }
        }
      }
}